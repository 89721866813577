
import { LakeQualityAvg } from '../Types/LakeQualityAvg';

export const getAllLakeQualityAvg = async (): Promise<LakeQualityAvg[]> => {
  console.log('Fetching all lake quality average data from:', `/all`);  // Log the full endpoint
  try {
    const response = await fetch("/api/lakeQualityAvg/all"); // Fetch the lake quality data
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseBody = await response.json(); // Parse the JSON data
    console.log('API response body:', responseBody); // Log the parsed response data
    return responseBody; // Return the parsed lake quality data
  } catch (error) {
    console.error('Error fetching lake quality average data:', error);
    throw error;
  }
};

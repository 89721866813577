import React, { useState } from 'react';
import LandingPage from './Components/LandingPage';
import StreamTable from './Components/StreamTable';
import Navbar from './Components/Navbar';
import ContainmentTable from './Components/ContaminantTable';
import ContaminantChart from './Components/ContaminantChart';
import LakeQualityTable from './Components/LakeQualityTable'; 

const App: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState('LandingPage');

  const renderPage = () => {
    switch (selectedPage) {
      case 'LandingPage':
        return <LandingPage />;
      case 'Stream':
        return <StreamTable />;
      case 'Contaminants':
        return <ContainmentTable />;
      case 'Contaminant Chart':
        return <ContaminantChart />;
      case 'Lake Quality': 
        return <LakeQualityTable />;
      default:
        return <LandingPage />;
    }
  };

  return (
    <div className="App">
      <Navbar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />

      {selectedPage === 'LandingPage' && (
        <header className="App-header">
          <h1>Welcome to WaterWize!</h1>
          <p>Your ultimate water source tracking application.</p>
        </header>
      )}

      <main className="content">
        {renderPage()}
      </main>
    </div>
  );
}

export default App;

import React from 'react';

interface FiltersProps {
  selectedDistrict: string;
  selectedContaminant: string;
  uniqueDistricts: string[];
  uniqueContaminants: string[];
  handleDistrictChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleContaminantChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContaminantFilters: React.FC<FiltersProps> = ({
  selectedDistrict,
  selectedContaminant,
  uniqueDistricts,
  uniqueContaminants,
  handleDistrictChange,
  handleContaminantChange,
}) => {
  return (
    <div className='filters-container'>
      <label htmlFor="district-filter">Filter by District: </label>
      <input
        type="text"
        id="district-filter"
        value={selectedDistrict}
        onChange={handleDistrictChange}
        placeholder="Type to filter by district"
        list="districts"
      />
      <datalist id="districts">
        {uniqueDistricts
          .filter(district => district.toLowerCase().includes(selectedDistrict.toLowerCase()))
          .map(district => (
            <option key={district.toString()} value={district.toString()} />
          ))}
      </datalist>
      <label htmlFor="contaminant-filter">Filter by Contaminants: </label>
      <input
        type="text"
        id="contaminant-filter"
        value={selectedContaminant}
        onChange={handleContaminantChange}
        placeholder="Type to filter by contaminant"
        list="contaminants"
      />
      <datalist id="contaminants">
        {uniqueContaminants
          .filter(contaminant => contaminant.toLowerCase().includes(selectedContaminant.toLowerCase()))
          .map(contaminant => (
            <option key={contaminant.toString()} value={contaminant.toString()} />
          ))}
      </datalist>
    </div>
  );
};

export default ContaminantFilters;

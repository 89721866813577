import React, { useEffect, useState } from 'react';
import { getAllContaminants } from '../Services/contaminantService';
import { Contaminants } from '../Types/Contaminants';
import ContaminantFilters from './ContaminantFilters';
import SortButton from './ContaminantSort';

const ContainmentTable: React.FC = () => {
  const [contaminantData, setContaminantData] = useState<Contaminants[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<string>('');
  const [selectedContaminant, setSelectedContaminant] = useState<string>('');
  const [isSortedAscending, setIsSortedAscending] = useState<boolean>(true);
  const [isSorted, setIsSorted] = useState<boolean>(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        let newData = await getAllContaminants();
        if (Array.isArray(newData)) {
          setContaminantData(newData);
        } else {
          setContaminantData([newData]);
        }
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    fetchData();
  }, []);

  const handleDistrictChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDistrict(event.target.value);
  };

  const handleContaminantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedContaminant(event.target.value);
  };

  const toggleSortOrder = () => {
    setIsSortedAscending(prev => !prev); // Switch between ascending and descending
    setIsSorted(true); 
  };
  
  const sortedData = isSorted
  ? [...contaminantData].sort((a, b) => {
      return isSortedAscending
        ? a.noOfExceedances - b.noOfExceedances
        : b.noOfExceedances - a.noOfExceedances;
    })
  : contaminantData; 

  const filteredData = sortedData.filter((data) => {
    return (
      (!selectedDistrict || data.district === selectedDistrict) &&
      (!selectedContaminant || data.contaminant === selectedContaminant)
    );
  });

  const uniqueDistricts: string[] = Array.from(new Set(contaminantData.map(data => data.district as string)));
  const uniqueContaminants: string[] = Array.from(new Set(
    contaminantData
      .filter(data => !selectedDistrict || data.district === selectedDistrict)
      .map(data => data.contaminant as string)
  ));

  return (
    <div>
      <h2>Contaminant Summary Table</h2>
      <ContaminantFilters
        selectedDistrict={selectedDistrict}
        selectedContaminant={selectedContaminant}
        uniqueDistricts={uniqueDistricts}
        uniqueContaminants={uniqueContaminants}
        handleDistrictChange={handleDistrictChange}
        handleContaminantChange={handleContaminantChange}
      />
      <SortButton isAscending={isSortedAscending} onToggle={toggleSortOrder} /> {/* Sort Button */}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Last Updated</th>
            <th>District</th>
            <th>Contaminant</th>
            <th>Contaminant Limit</th>
            <th>Contaminant Unit</th>
            <th>No. of Exceedances</th>

          </tr>
        </thead>
        <tbody>
          {filteredData.map((data: Contaminants) => (
            <tr key={data.id}>
              <td>{data.id}</td>
              <td>{new Date(data.lastUpdated).toLocaleDateString()}</td>
              <td>{data.district}</td>
              <td>{data.contaminant}</td>
              <td>{data.contaminantLimit}</td>
              <td>{data.contaminantUnit}</td>
              <td>{data.noOfExceedances}</td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContainmentTable;
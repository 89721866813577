import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ContaminantChart: React.FC = () => {
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [],
  });
const processData = (data: any) => {
    const frequencyData = data.reduce((acc: any, item: any) => {
      acc[item.contaminant] = (acc[item.contaminant] || 0) + 1;
      return acc;
    }, {});

    const labels = Object.keys(frequencyData);
    const newLabels = labels.map(label => label.replace(/\s+/g, ' ').trim());
    const datasetData = Object.values(frequencyData);

    setChartData({
      labels: newLabels,
      datasets: [
        {
          label: 'Contaminant Frequency',
          data: datasetData,
          backgroundColor: 'rgba(25, 160, 160, 0.5)',
        },
      ],
    });
  };

useEffect(() => {
  axios
    .get('api/contaminant/all')
    .then((response) => {
      processData(response.data); // Now calling the processData function
    })
    .catch((error) => console.error('Error fetching data:', error));
}, []);

  if (chartData.labels.length === 0) {
    return <p>No data available to display the chart.</p>; // Handle no data case
  }
 // Style definitions
  const chartContainerStyle = {
    width: '150%',
    height: 'auto',
    margin: '0 auto',
    backgroundColor: 'rgb(211, 211, 211)',
  };

  const chartTitleStyle = {
    color: 'Black',
  };

  return (
    <div style={chartContainerStyle}> {/* Applied extracted style */}
    <h2 style={chartTitleStyle}>Contaminant Frequency Chart</h2> {/* Applied extracted style */}

      <Bar
        data={chartData}
        options={{
            responsive: true,
            plugins: {
                legend: { 
                    position: 'top', 
                    labels: { font: { size: 20 } }
                },
                title: { 
                    display: true,
                    color: 'Black',
                    text: 'Contaminant Frequency by Type', 
                    font: { size: 24 }
                },
            },
            scales: {
                x: {
                  title: {
                    display: true, 
                    text: 'Contaminant Types', // Label for the x-axis
                    color: 'Black',
                    font: { size: 18 }
                  },
                    ticks: {
                        color: 'Black',
                        maxRotation: 45, // Rotate the labels vertically
                        minRotation: 45, // Ensure the rotation is fixed at 90 degrees
                        autoSkip: false, // Prevent skipping labels
                        font: {
                            size: 10, // Font size for x-axis labels
                        },
                    },
                },
                y: {
                  title: {
                    display: true, 
                    text: 'Frequency', // Label for the y-axis
                    color: 'Black',
                    font: { size: 18 }
                  },
                    beginAtZero: true,
                    ticks: {
                        color: 'Black',
                        font: {
                            size: 18, // Font size for y-axis labels
                        },
                    },
                },
            },
            layout: {
                padding: {
                    left: 30,
                    right: 30,
                    top: 30,
                    bottom: 50, // Adjust bottom padding
                },
            },
        }}
        />

    </div>
  );
};

export default ContaminantChart;

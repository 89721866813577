import React, { useEffect, useState } from 'react';
import { getAllStreams } from '../Services/streamService';
import { Streams } from '../Types/Streams';
import StreamGraph from './StreamGraph';

const StreamTable: React.FC = () => {
  const [streamData, setStreamData] = useState<Streams[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let newData = await getAllStreams();
        if (Array.isArray(newData)) {
          setStreamData(newData);
        } else {
          setStreamData([newData]);
        }
      } catch (error) {
        console.error('Error fetching streams:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {streamData.length > 0 && <StreamGraph data={streamData} />}
      <h2>Stream Summary Table</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Last Updated</th>
            <th>Station</th>
            <th>Parameter</th>
            <th>Description</th>
            <th>Year</th>
            <th>Date</th>
            <th>Time</th>
            <th>Field Number</th>
            <th>Result</th>
            <th>Units</th>
          </tr>
        </thead>
        <tbody>
          {streamData.map((data: Streams) => (
            <tr key={data.id}>
              <td>{data.id}</td>
              <td>{new Date(data.lastUpdated).toLocaleDateString()}</td>
              <td>{data.station}</td>
              <td>{data.parm}</td>
              <td>{data.parmDescription}</td>
              <td>{data.year}</td>
              <td>{new Date(data.date).toLocaleDateString()}</td>
              <td>{new Date(data.time).toLocaleTimeString()}</td>
              <td>{data.fieldNo}</td>
              <td>{data.result}</td>
              <td>{data.units}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StreamTable;
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { LakeQuality } from '../Types/LakeQuality';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import Modal from 'react-modal';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface LakeQualityGraphProps {
    data: LakeQuality;
    onClose: () => void;
    isOpen: boolean;
}

const LakeQualityGraph: React.FC<LakeQualityGraphProps> = ({ data, onClose, isOpen }) => {
    const [chartData, setChartData] = useState<any>({
        labels: [],
        datasets: [],
    });


    useEffect(() => {
        const labels = ['Ca', 'Cl', 'DIC', 'DOC', 'K', 'Mg', 'Na', 'NH4', 'NO3', 'Gran Alkalinity', 'pH'];
        if (!data) {
            return;
        }
        const datasetData = [data.ca, data.cl, data.dic, data.doc, data.k, data.mg, data.na, data.nh4, data.n03, data.granAlkalinity, data.pH];

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Concentration',
                    data: datasetData,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
            ],
        });

        console.log('Chart Data:', { labels, datasetData }); // Log the chart data being set
    }, [data]);

    if (!data?.ca || chartData.labels.length === 0) {
        return <p>No data available to display the chart.</p>; // Handle no data case
    }

    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onClose}
            style= {{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    width: '1000px',
                    height: '47%',
            }
        }}>
         <div className="popup-inner" style ={{ width: '800px', height: '350px', margin: '0 auto', backgroundColor: 'rgb(211, 211, 211)'}}>
            <h3 style={{ color: 'Black', marginTop: '0' }}>Lake Quality Chart for {data.lake}, {data.sampleDate}</h3>
            <Bar
                data={chartData}
                options={{
                scales: {
                    x: {
                    title: {
                        display: true,
                        text: 'Contaminants',
                        color: 'Black',
                        font: { size: 18 },
                    },
                    ticks: {
                        color: 'Black',
                        maxRotation: 45,
                        minRotation: 45,
                        autoSkip: false,
                        font: {
                        size: 10,
                        },
                    },
                    },
                    y: {
                    title: {
                        display: true,
                        text: 'Concentration',
                        color: 'Black',
                        font: { size: 18 },
                    },
                    beginAtZero: true,
                    ticks: {
                        color: 'Black',
                        font: {
                        size: 18,
                        },
                    },
                    },
                },
                layout: {
                    padding: {
                    left: 20,
                    right: 20,
                    top: 20,
                    bottom: 10,
                    },
                },
                }}
            />
            </div>
        </Modal>
    );
};

export default LakeQualityGraph;
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement, 
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { LakeQualityAvg } from '../Types/LakeQualityAvg';


ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement);

interface LakeQualityAvgGraphProps {
    lakeQualityAvgData: Array<LakeQualityAvg>;
    
}

const LakeQualityAvgGraph: React.FC<LakeQualityAvgGraphProps> = ({lakeQualityAvgData}) => {
    const data:any = {
        labels: lakeQualityAvgData.map((data: { lake: string; }) => data.lake),
        datasets: [
            {
                label: 'caAvg',
                data: lakeQualityAvgData.map((data: { caAvg: number; }) => data.caAvg),
                borderColor: '#CDA434',
                backgroundColor: '#CDA434',
                borderWidth: 2,
                fill: true,
            },

            {
                label: 'clAvg',
                data: lakeQualityAvgData.map((data: { clAvg: number; }) => data.clAvg),
                borderColor: '#8D948D',
                backgroundColor: '#8D948D',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'dicAvg',
                data: lakeQualityAvgData.map((data: { dicAvg: number; }) => data.dicAvg),
                borderColor: '#1C1C1C',
                backgroundColor: '#1C1C1C',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'docAvg',
                data: lakeQualityAvgData.map((data: { docAvg: number; }) => data.docAvg),
                borderColor: '#B44C43',
                backgroundColor: '#B44C43',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'kAvg',
                data: lakeQualityAvgData.map((data: { kAvg: number; }) => data.kAvg),
                borderColor: '#6C3B2A',
                backgroundColor: '#6C3B2A',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'mgAvg',
                data: lakeQualityAvgData.map((data: { mgAvg: number; }) => data.mgAvg),
                borderColor: '#2E3A23',
                backgroundColor: '#2E3A23',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'naAvg',
                data: lakeQualityAvgData.map((data: { naAvg: number; }) => data.naAvg),
                borderColor: '#3B83BD',
                backgroundColor: '#3B83BD',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'nh4Avg',
                data: lakeQualityAvgData.map((data: { nh4Avg: number; }) => data.nh4Avg),
                borderColor: '#A18594',
                backgroundColor: '#A18594',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'n03Avg',
                data: lakeQualityAvgData.map((data: { n03Avg: number; }) => data.n03Avg),
                borderColor: '#412227',
                backgroundColor: '#412227',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'granAlkalinityAvg',
                data: lakeQualityAvgData.map((data: { granAlkalinityAvg: number; }) => data.granAlkalinityAvg),
                borderColor: '#C2B078',
                backgroundColor: '#C2B078',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'pHAvg',
                data: lakeQualityAvgData.map((data: { pHAvg: number; }) => data.pHAvg),
                borderColor: '#317F43',
                backgroundColor: '#317F43',
                borderWidth: 2,
                fill: true,
            }
            
        ],
    };
  
    const options:any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: { 
                display: true,
                color: 'Black',
                text: 'Averages of Contaminant by Lake Quality', 
                font: { size: 24 }
            },
        },
        scales: {
            x: {
            title: {
                display: true,
                text: 'Lakes',
                color: 'Black',
                font: { size: 18 },
            },
            ticks: {
                color: 'Black',
                maxRotation: 45,
                minRotation: 45,
                autoSkip: false,
                font: {
                    size: 10,
                },
            },
            },
            y: {
            title: {
                display: true,
                text: 'Concentration',
                color: 'Black',
                font: { size: 18 },
            },
            beginAtZero: true,
            ticks: {
                color: 'Black',
                font: {
                size: 18,
                },
            },
            },
        }
    };

    return (
        <div style={{ width: '100%', height: '50vh' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default LakeQualityAvgGraph;
import { LakeQuality } from '../Types/LakeQuality';

export const getAllLakeQuality = async (): Promise<LakeQuality[]> => {
  console.log('Fetching all lake quality data from:', `/all`);  // Log the full endpoint
  try {
    const response = await fetch("/api/lakeQuality/all"); // Fetch the lake quality data
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseBody = await response.json(); // Parse the JSON data
    console.log('API response body:', responseBody); // Log the parsed response data
    return responseBody; // Return the parsed lake quality data
  } catch (error) {
    console.error('Error fetching lake quality data:', error);
    throw error;
  }
};

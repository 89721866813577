import React, { useEffect, useState } from 'react';
import { getAllLakeQuality } from '../Services/lakeQualityService';
import { getAllLakeQualityAvg } from '../Services/lakeQualityAvgService';
import { LakeQuality } from '../Types/LakeQuality';
import LakeQualityGraph from './LakeQualityGraph';
import { LakeQualityAvg } from '../Types/LakeQualityAvg';
import LakeQualityAvgGraph from './LakeQualityGraphAvg';
import SortButton from './LakeQualitySort';
interface TestingProps {
  displayAvgGraph?: boolean; // Optional prop with a defualt value of true but may be set to false for testing
}


const LakeQualityTable: React.FC<TestingProps> = ({ displayAvgGraph = true }) => {
  const [lakeQualityData, setLakeQualityData] = useState<LakeQuality[]>([]);
  const [selectedLakeData, setSelectedLakeData] = useState<LakeQuality | null>(null);
  const [lakeQualityAvgData, setLakeQualityAvgData] = useState<LakeQualityAvg[]>([]);
  const [showGraph, setShowGraph] = useState(false);
  const [isSortedAscending, setIsSortedAscending] = useState<boolean>(true);
  const [isSorted, setIsSorted] = useState<boolean>(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newData = await getAllLakeQuality();
        setLakeQualityData(Array.isArray(newData) ? newData : [newData]);

        const newDataAvg = await getAllLakeQualityAvg();
        setLakeQualityAvgData(Array.isArray(newDataAvg) ? newDataAvg : [newDataAvg]);
      } catch (error) {
        console.error('Error fetching lake quality data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleSortOrder = () => {
    setIsSortedAscending(prev => !prev); // Switch between ascending and descending
    setIsSorted(true);
  };

  const sortedData = isSorted
    ? [...lakeQualityData].sort((a, b) => {
        return isSortedAscending
          ? new Date(a.sampleDate).getTime() - new Date(b.sampleDate).getTime()
          : new Date(b.sampleDate).getTime() - new Date(a.sampleDate).getTime();
      })
    : lakeQualityData;

  const handleRowClick = (data: LakeQuality) => {
    setSelectedLakeData(data);
    console.log(data);
    setShowGraph(true);
  };

  const handleCloseGraph = () => {
    setShowGraph(false);
    setSelectedLakeData(null);
  };

  return (
    <div>

      { displayAvgGraph ? 
        <div style={{ borderRadius: '10px', width: '100vh', backgroundColor: '#90BDDC', padding: '30px 0'}}>
        <div style={{ backgroundColor: '#D3D3D3', borderRadius: '10px', padding: '20px', margin: '0 auto', maxWidth: '80%' }}>
          <LakeQualityAvgGraph lakeQualityAvgData={lakeQualityAvgData} />
        </div>
      </div> : null
      }
      
       {showGraph && selectedLakeData && (
          <LakeQualityGraph data={selectedLakeData} onClose={handleCloseGraph} isOpen ={showGraph} />
      )}
      
      <h2>Lake Quality Summary Table</h2>
      <SortButton isAscending={isSortedAscending} onToggle={toggleSortOrder} /> {/* Sort Button */}
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Sample Date</th>
            <th>Lake</th>
            <th>Last Updated</th>
            <th>Ca</th>
            <th>Cl</th>
            <th>DIC</th>
            <th>DOC</th>
            <th>K</th>
            <th>Mg</th>
            <th>Na</th>
            <th>NH4</th>
            <th>NO3</th>
            <th>Gran Alkalinity</th>
            <th>pH</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((data: LakeQuality) => (
            <tr key={data.id}>
              <td>{data.id}</td>
              <td>{data.sampleDate}</td>
              <td>{data.lake}</td>
              <td>{new Date(data.lastUpdated).toLocaleDateString()}</td>
              <td>{data.ca}</td>
              <td>{data.cl}</td>
              <td>{data.dic}</td>
              <td>{data.doc}</td>
              <td>{data.k}</td>
              <td>{data.mg}</td>
              <td>{data.na}</td>
              <td>{data.nh4}</td>
              <td>{data.n03}</td>
              <td>{data.granAlkalinity}</td>
              <td>{data.pH}</td>
              <td>
                <button onClick={() => handleRowClick(data)}>View Graph</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LakeQualityTable;

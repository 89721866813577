import React from 'react';

interface NavbarProps {
  selectedPage: string;
  setSelectedPage: (page: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedPage, setSelectedPage }) => {
  return (
    <nav>
      <ul>
        <li>
          <a 
            href="#waterwize" 
            onClick={() => setSelectedPage('LandingPage')}
            className={selectedPage === 'LandingPage' ? 'active' : ''}
          >
            WaterWize
          </a>
        </li>
        <li>
          <a 
            href="#stream" 
            onClick={() => setSelectedPage('Stream')}
            className={selectedPage === 'Stream' ? 'active' : ''}
          >
            Stream
          </a>
        </li>
        <li>
          <a 
            href="#contaminants" 
            onClick={() => setSelectedPage('Contaminants')}
            className={selectedPage === 'Contaminants' ? 'active' : ''}
          >
            Contaminants
          </a>
        </li>
        <li>
          <a 
            href="#chart" 
            onClick={() => setSelectedPage('Contaminant Chart')}
            className={selectedPage === 'Contaminant Chart' ? 'active' : ''}
          >
            Contaminant Chart
          </a>
        </li>
        <li>
          <a 
            href="#lake-quality" 
            onClick={() => setSelectedPage('Lake Quality')}
            className={selectedPage === 'Lake Quality' ? 'active' : ''}
          >
            Lake Quality
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;

import { Contaminants } from '../Types/Contaminants';

const API_URL = "/api/contaminant/all";

export const getAllContaminants = async () => {
  try {
    const response = await fetch(API_URL);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    return await response.json();
  } catch (error) {
    console.error("Error fetching contaminants:", error);
    throw error;
  }
};

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Streams } from '../Types/Streams';
import 'chart.js/auto';

interface GraphProps {
  data: Streams[];
}

const StreamGraph: React.FC<GraphProps> = ({ data }) => {
  const stations = Array.from(new Set(data.map((item) => item.station)));
  const averageResults = stations.map(
    (station) => {
      const stationData = data.filter((item) => item.station === station);
      return stationData.reduce((acc, item) => acc + parseFloat(item.result), 0) / stationData.length;
    }
  );

  const chartData = {
    labels: stations,
    datasets: [
      {
        label: 'Average Results for Each Station',
        data: averageResults,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Stations',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Average Result',
        },
      },
    },
  };

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px' }}>
      <h3 style={{ textAlign: 'center', color: '#333' }}>Average Results for Each Station</h3>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default StreamGraph;

import React from 'react';

interface SortButtonProps {
  isAscending: boolean;
  onToggle: () => void;
}

const SortButton: React.FC<SortButtonProps> = ({ isAscending, onToggle }) => {
  return (
    <button onClick={onToggle}>
      {isAscending ? 'Sort By Date in Descending Order' : 'Sort By Date in Ascending Order'} 
    </button>
  );
};

export default SortButton;
